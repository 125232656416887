export type SolvedHistory = {
  time: String
  duration: any
  mode: string
  solvedArray: Array<String>
  startArray: Array<String>
  isSolved: boolean
}

// function setup(){
//   let db;
//   const req = indexedDB.open("solvedHistory", 1);
//   req.onerror = (e) => console.log("error occured in DB: ", JSON.stringify(e))
//   req.onsuccess = (e) => db = (e.target as IDBRequest).result

// }

// export function SolvedHistryProvider(children: any){
//   const SolvedHistryCtx = createContext<SolvedHistry[]>()

//   return (
//     <SolvedHistryCtx.Provider value={0}>
//       {children}
//     </SolvedHistryCtx.Provider>
//   )
// }

export function saveHistoryToLocalStorage(history: SolvedHistory){
  const h = getHistoryFromLocalStorage();
  localStorage.setItem("solvedHistory", JSON.stringify([...h, history]))
}

export function clearHistoryFromLocalStorage(){
  localStorage.clear()
}

export function getHistoryFromLocalStorage(): SolvedHistory[]{
  const item = localStorage.getItem("solvedHistory")
  if(item == null){
    return [] as SolvedHistory[];
  }
  return JSON.parse(item!) as SolvedHistory[];
}