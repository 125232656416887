import React from 'react';
import { Game } from './Game';
import './App.css';
import { SudokuProvider } from './context/SudokuContext';

/**
 * App is the root React component.
 */
export const App: React.FC<{}> = () => {
  console.log("© 2020 Amith Raravi - source code on Github")
  return (
    <SudokuProvider>
      <Game />
    </SudokuProvider>
  );
}
