import React from 'react';
import { useState } from 'react';
import { getHistoryFromLocalStorage, clearHistoryFromLocalStorage } from '../../SolvedHistory';

/**
 * React component for the Footer Section.
 */
export const Footer = () => {
  const [e, setRow] = useState<any>(Date().toString())
  return (
    <footer className="footer flex justify-center w-screen">
      <div className='w-screen/2'>
        <div className='hidden'>
          {`updates on:${e}`}
        </div>
        <div className='pt-30' />
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th><button className='btn btn-sm' onClick={() => {
                clearHistoryFromLocalStorage()
                setRow(Math.random())
              }}>Delete</button></th>
              <th>Day</th>
              <th>Time</th>
              <th>Mode</th>
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            {getHistoryFromLocalStorage().map((h, i) => {
              return (
                <tr>
                  <th>{i + 1}</th>
                  <td>{h.time}</td>
                  <td>{h.mode}</td>
                  <td>{h.duration}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </footer>
  )
}
